import { apolloClient } from "@/api/apolloClient";
import {CREATE_PICTURE, UPDATE_PICTURE, DELETE_PICTURE} from "@/api/graphql/mutation/PictureMutation"
import {PICTURES} from "@/api/graphql/query/PictureQuery"

// Temporary solution to a bug where string with diacritics saves as ???
function removeDiacritics(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export default {
    createPicture: function(form){
        console.log("Creating Picture with title : " + form.title);
        const {chosenPicture, countryId, localizations, originalLanguage} = form
        // const localizationsWithFixedTitle = localizations.map(localization => {return {...localization, title: removeDiacritics(localization.title) }})
        const userRole = localStorage.getItem("role");
        const author = localStorage.getItem("username");

            return apolloClient.mutate({
                mutation: CREATE_PICTURE,
                variables: {
                    file: chosenPicture,
                    country: countryId,
                    originalLanguage,
                    localizations: localizations
                },
                update: async (store, {data:{createPicture}}) => {
                    let data = await store.readQuery({query: PICTURES, variables: {filter: {searchString: null, countryCode: null, author: userRole == "AUTHOR" ? author : null}}})
                    if (data) {
                        data = {
                            ...data,
                            pictures: [
                              ...data.pictures,
                              createPicture,
                            ],
                          }
                        store.writeQuery({ query: PICTURES, variables: {filter: {searchString: null, countryCode: null, author: userRole == "AUTHOR" ? author : null}}, data })
                    }
                }
            }).then((result) => {
                console.log("Picture created: ", result);
                return result;
            });
    },
    updatePicture: function(form, pictureId){
        console.log("Updating Picture with title : " + form.title);
        const {chosenPicture, country: {id}, localizations, originalLanguage} = form
        // const localizationsWithFixedTitle = localizations.map(localization => {return {...localization, title: removeDiacritics(localization.title) }})

            return apolloClient.mutate({
                mutation: UPDATE_PICTURE,
                variables: {
                    id: pictureId,
                    file: chosenPicture,
                    country: id,
                    originalLanguage,
                    localizations: localizations
                }
            }).then((result) => {
                console.log("Picture updated: ", result);
                return result;
            });
    },
    deletePicture: function(pictureId){
        console.log("Delete Picture with ID : " + pictureId);
        const userRole = localStorage.getItem("role");
        const author = localStorage.getItem("username");
            return apolloClient.mutate({
                mutation: DELETE_PICTURE,
                variables: {
                    pictureId
                },
                update: async (store) => {
                    let data = await store.readQuery({query: PICTURES, variables: {filter: {searchString: null, countryCode: null, author: userRole == "AUTHOR" ? author : null}}})
                    let dataCopy = {...data}
                    dataCopy.pictures = [...dataCopy.pictures]
                    const index = dataCopy.pictures.findIndex(o => o.id === pictureId)
                    if (index !== -1) {
                        dataCopy.pictures.splice(index, 1)
                    }
                    store.writeQuery({ query: PICTURES, variables: {filter: {searchString: null, countryCode: null, author: userRole == "AUTHOR" ? author : null}}, data: dataCopy })
                }
            }).then((result) => {
                console.log("Picture deleted: ", result);
                return result;
            });
    },
}
