import gql from 'graphql-tag'

export const COUNTRIES = gql`
    query AllCountriesQuery {
        countries: countries {
            id,
            code,
            nameCzech,
            nameEnglish
        }
    }`

export const CITIES_BY_COUNTRIES = gql`
    query AllCitiesByCountriesQuery ($codes: [String!]!)  {
        cities: citiesFromCountriesByCodes(codes: $codes){
            code,
            name
        }
    }`
