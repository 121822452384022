<template>
  <div :class="{'manage-picture-page': !whiteInputs}" class="text-left">
    <b-alert :show="alert.dismissCountDown" fade id="alert"
            @dismiss-count-down="countDownChanged" class="alert-success">{{alert.notification}}
    </b-alert>
    <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger" style="padding-right: 45px"
              @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">{{alertDanger.notification}}
              <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
    </b-alert>
    <div v-if="pictureId && initialLoading" class="admin-page-loading">{{ $t("loading.loading") }}</div>
    <div v-if="$route.params.pictureId" v-show="!pictureId || !initialLoading" @dragover="onDragOver" @drop="onDrop" @dragleave="onDragLeave">
      <div class="d-flex justify-content-between mb-4">
        <h1 class="pictures-title"><span v-if="$route.name == 'adminManagePicture'" class="arrow arrow--left" @click='$router.go(-1)'>
          </span>{{form.localizations[0].title}}
        </h1>
        <div class="buttons-localization" v-if="!isAuthor">
          <TrekioButton v-if="form.localizations.length > 1" secondary @click="toggleManageLocalizations">{{ isManagingLocalizations ? $t("buttons.hideTranslations") : $t("buttons.showTranslations")}}</TrekioButton>
          <TrekioButton v-if="languagesToBeTranslated.length" primary @click="$bvModal.show('create-language-translation-modal')">{{ $t("buttons.createTranslation") }}</TrekioButton>
        </div>
        <b-modal size="fit-content" body-class="p-0" class="modal" id="create-language-translation-modal" hide-footer hide-header>
          <div class="language-option" v-for="language in languagesToBeTranslated" :key="language.code" @click="createTranslation(language)">{{language.nameEnglish}}</div>
        </b-modal>

        <UnsavedChangesModal :isModalVisible="isUnsavedChangesModalVisible" @hideModal="isUnsavedChangesModalVisible = false" @discardChangesAndContinue="discardChangesAndContinue"/>

      </div>

      <LocalizationSelects v-if="!isAuthor" :isManagePicturePage="true" style="margin-bottom: 30px;" :isManagingLocalizations="isManagingLocalizations" :originalLanguage="form.originalLanguage" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :languages="languages"
        :localizations="form.localizations" :whiteBg="whiteInputs" :translatedLanguages="translatedLanguages" @setOriginalLanguage="setOriginalLanguage" @setPrimaryLanguage="setPrimaryLanguage" @showLocalization="showLocalization"
      />

      <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
        <form @submit.prevent="onSubmit()" class="form d-flex flex-column" novalidate v-for="localization in form.localizations" :key="localization.language">
          <div class="form-content-container d-flex" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
            <div class=" d-flex flex-column inputs h-100 w-100" style="gap: 30px; max-width: 700px;">
              <TrekioInput id="title" v-model="localization.title" :white-bg="whiteInputs" :label="$t('inputLabels.pictureTitle')" maxLength="255" :disabled="isManagingLocalizations && primaryLanguage == localization.language"
                :error="error.pictureTitle" placeholder=" " required
              />

              <TrekioSelect :white-bg="whiteInputs" v-if="!isManagingLocalizations" :label="$t('inputLabels.countries')" v-model="form.country" searchInput @searchInput="value => countriesSearchString = value" :searchString="countriesSearchString" required
                :text="form.country[countryLocalization] ? form.country[countryLocalization] : $t('admin.notSelected')" :error="error.country" 
              >
                <TrekioSelectOption v-for="country in filterCountries" :key="country.id" :value="country">{{ country[countryLocalization] }}</TrekioSelectOption>
              </TrekioSelect>

              <div class="input-container file-input-container" v-if="!isManagingLocalizations">
                <div class="file-input-wrapper position-relative" :class="{'error': error.file, 'size-error': isFileTooLarge, 'dashed-border': isDraggingFileOver}">
                  <label for="image-input" :class="{'white-bg': whiteInputs}" data-required>{{ $t("inputLabels.pictureInputFile") }}</label>
                  <p class="file-input-name">{{form.fileName}}</p>
                  <input type="file" id="image-input" name="image"  accept="image/png, image/jpeg" @change="onInputFile">
                  <TrekioButton class="upload-button" primary>{{ $t("buttons.upload") }}</TrekioButton>
                </div>
                <div class="hint mt-2">{{ $t("inputHints.fileSize") }}</div>
                <div class="input-alert" v-if="error.file">{{ error.file }}</div>
              </div>
              <div class='form-group' v-if="!isManagingLocalizations && imgWidth && imgHeight">
                <div class='image-warning-red' v-if="imgWidth < 1200 || imgHeight < 727">
                  {{ $t("inputHints.resolutionTooSmall") }}
                </div>
                <div class='image-warning-orange' v-if="((imgWidth < 1974 && imgWidth > 1199) || (imgHeight < 1480 && imgHeight > 726)) && !(imgWidth < 1200 || imgHeight < 727)">
                  {{ $t("inputHints.resolutionCouldBeHigher") }}
                </div>
                <div class='image-warning-green' v-if="imgWidth > 1973 && imgHeight > 1479">
                  {{ $t("inputHints.resolutionIsCorrect") }}
                </div>
                {{ $t("inputHints.resolutionIs", [imgWidth, imgHeight]) }}
              </div>
            </div>
            <img v-show="!isManagingLocalizations" class="display-picture" :src="imageSourceSmall" :class="{'dashed-border': isDraggingFileOver}" @click="$bvModal.show('fullscreen-picture')" >
            <b-modal size="fit-content" class="modal pl-0" id="fullscreen-picture" hide-footer hide-header>
              <div @click="$bvModal.hide('fullscreen-picture')" class="x"></div>
              <figure v-lazyload class="position-relative mb-0">
                <div class="loading-circle position-absolute"><div></div><div></div><div></div><div></div></div>
                <img class="picture" :data-url="imageSource">
              </figure>
            </b-modal>
          </div>
        </form>
      </div>
      <div class="picture-assigned-to-container" v-if="isAssigned">
        <p class="picture-is-assigned-to-title">{{ $t("admin.pictureIsAssignedTo") }}</p>

        <div class="assigned-to-container">
          <div class="assigned-to-category" v-if="canDelete && canDelete.travelTips.length > 0">
            <p class="assigned-to-title">{{ `${$t('general.travelTips')} (${canDelete.travelTips.length})` }}</p>
            <p v-for="travelTip in canDelete.travelTips" :key="travelTip.id">
              • <router-link :to="'/admin/manageTravelTip/' + travelTip.id" target="_blank">{{ travelTip.title ? travelTip.title : $t("general.link") }}</router-link>
            </p>
          </div>
          <div class="assigned-to-category" v-if="canDelete && canDelete.itineraryDays.length > 0">
            <p class="assigned-to-title">{{ `${$t('general.daysCapitalized')} (${canDelete.itineraryDays.length})` }}</p>
            <div v-for="itineraryDay in canDelete.itineraryDays" :key="itineraryDay.id">
              <p>
                • "<b>{{ itineraryDay.title }}</b>" {{ $t("admin.inTravelTip") }}
                <router-link :to="'/admin/manageTravelTip/' + itineraryDay.travelTip.id" target="_blank">{{ itineraryDay.travelTip.title ? itineraryDay.travelTip.title : $t("general.link") }}</router-link>
              </p>
            </div>
          </div>
          <div class="assigned-to-category" v-if="canDelete && canDelete.itineraryItems.length > 0">
            <p class="assigned-to-title">{{ `${$t('general.itineraryItems')} (${canDelete.itineraryItems.length})` }}</p>
            <div v-for="itineraryItem in canDelete.itineraryItems" :key="itineraryItem.id">
              <p>
                • "<b>{{ itineraryItem.title }}</b>" {{ $t("admin.inDayWithNumber") }} <b>{{ getDayNumber(itineraryItem.id) }}</b> {{ $t("admin.inTravelTip") }}
                <router-link :to="'/admin/manageTravelTip/' + itineraryItem.travelTip.id" target="_blank">{{ itineraryItem.travelTip.title ? itineraryItem.travelTip.title : $t("general.link") }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <b-modal class="modal" id="picture-delete-modal" hide-footer hide-header>
        <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
          <h3 class="moda-title pb-3">{{ $t("modals.pictureDeleteTitle") }}</h3>
          <p class="moda-text pb-5">{{ $t("modals.pictureDeleteText") }}</p>
          <div class="pb-5 d-flex" style="gap: 40px;">
            <TrekioButton secondary @click="$bvModal.hide('picture-delete-modal')">{{$t("general.back")}}</TrekioButton>
            <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.delete") }}</TrekioButton>
          </div>
        </div>
      </b-modal>
    </div>

    <div v-else>
      <div>
        <h1 class="h1__admin"><span v-if="$route.name == 'adminManagePicture'" class="arrow arrow--left" @click='$router.go(-1)'>
        </span>{{isTravelTipPicture ? $t("admin.addPicture") : $t("admin.addPictures")}}
      </h1>
      <LocalizationSelects v-if="!isAuthor" :isManagePicturePage="true" style="margin-bottom: 30px;" :isManagingLocalizations="isManagingLocalizations" :originalLanguage="form.originalLanguage" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :languages="languages"
        :localizations="form.localizations" :whiteBg="whiteInputs" :translatedLanguages="translatedLanguages" @setOriginalLanguage="setOriginalLanguage" @setPrimaryLanguage="setPrimaryLanguage" @showLocalization="showLocalization"
      />

      </div>
      <TrekioSelect :white-bg="whiteInputs" v-if="!isManagingLocalizations" :label="$t('inputLabels.countries')" v-model="form.country" searchInput @searchInput="value => countriesSearchString = value" :searchString="countriesSearchString" required
        :text="form.country[countryLocalization] ? form.country[countryLocalization] : $t('admin.notSelected')" :error="error.country"
        >
        <TrekioSelectOption v-for="country in filterCountries" :key="country.id" :value="country">{{ country[countryLocalization] }}</TrekioSelectOption>
      </TrekioSelect>
      <div class="input-list">
        <div class="input-with-image" v-for="(picture, index) in picturesToCreate" :key="picture.src">
          <div class="d-flex">
            <span role="button" class="remove-picture" @click="removePictureToUpload(index)">&#x2715;</span>
            <TrekioInput required :white-bg="whiteInputs" :label="$t('inputLabels.pictureTitle')" :error="picture.error" :id="'pictureTitle' + picture.src" v-model="picture.title"/>
          </div>
          <img :src="picture.src" alt="">
          </div>
        </div>
        <div v-if="(isTravelTipPicture && !picturesToCreate.length) || !isTravelTipPicture"
          class="upload-box" :class="{'dashed-border': isDraggingFileOver}"
          @click="triggerFileInput"
          @dragover="onDragOver"
          @dragleave="onDragLeave"
          @drop="handleDrop"
          >
          <p>{{ isTravelTipPicture ? $t('admin.clickOrDragImageToUpload') : $t('admin.clickOrDragImagesToUpload') }}</p>
          <input
          type="file"
            ref="fileInput"
            :multiple="!isTravelTipPicture"
            accept="image/*"
            @change="handleFiles"
            style="display: none;"
            />
          </div>

        </div>
        <div class="fixed-buttons" :class="{'manage-localization': isManagingLocalizations}">
          <div>
            <div v-if="$route.params.pictureId && !isManagingLocalizations" v-tooltip.top="isAssigned ? $t('info.deleteAssignedPicture'): null" >
              <TrekioButton type="button" secondary @click="$bvModal.show('picture-delete-modal')" :disabled="$apollo.queries.canDelete.loading || isAssigned"> {{ $t("buttons.delete") }}</TrekioButton>
            </div>
            <div v-if="form.originalLanguage && ($route.params.pictureId || picturesToCreate.length)" v-tooltip.top="$route.name == 'adminManagePicture' && !isEditable ? $t('info.updateAssignedPicture'): null">
              <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" @click.prevent="onSubmit()" primary :disabled="$route.name == 'adminManagePicture' && ($apollo.queries.canDelete.loading || !isEditable)">{{ isManagingLocalizations ? $t('buttons.saveTranslations') : $t("buttons.save") }}</TrekioButton>
            </div>
          </div>
        </div>
    </div>
  </template>

<script>
import ImageService from "@/services/ImageService";
import {COUNTRIES} from "@/api/graphql/query/CountryQuery";
import {PICTURE_BY_ID} from "@/api/graphql/query/PictureQuery";
import {CLEVERTRIP_IMG_URL}  from '@/definitions';
import fileUtils from "@/utils/fileUtils";
import LazyLoadDirective from "@/components/LazyLoadDirective.js"
import sharedUtils from "../../utils/sharedUtils";
import { CAN_DELETE_PICTURE } from "../../api/graphql/query/PictureQuery";
import { assignItineraryDayLocalizationProperties, assignItineraryItemLocalizationProperties, assignTravelTipLocalizationProperties } from "../../utils/LocalizationDataConverter";
import UnsavedChangesModal from '@/components/admin/UnsavedChangesModal'
import LocalizationSelects from '@/components/admin/LocalizationSelects'


export default {
  name: "AdminManagePicture",

  directives: {
    lazyload: LazyLoadDirective
  },

  components: {
    UnsavedChangesModal,
    LocalizationSelects,
  },

  props: {
    whiteInputs: Boolean,
    preselectedCountry: Object,
    isTravelTipPicture: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      picturesToCreate: [],
      initialLoading: true,
      loading: '',
      loadingDelete: '',
      picture: null,
      countries: [],
      countriesSearchString: "",
      imgURL: CLEVERTRIP_IMG_URL,
      form: {
        author: null,
        fileName: '',
        country: {
          id: null,
          code: '',
          nameCzech: '',
          nameEnglish: ''
        },
        originalLanguage: this.$store.state.currentUserRole === "ADMIN_SUPERVISOR" ? null : this.$store.state.preferredLanguage,
        localizations: [],
        chosenPicture: null,
      },
      lastSavedForm: null,
      error: {
        pictureTitle: '',
        country: '',
        file: ''
      },
      editedLanguage: null,
      isManagingLocalizations: false,
      primaryLanguage: this.$store.state.currentUserRole === "ADMIN_SUPERVISOR" ? null : this.$store.state.preferredLanguage,
      isUnsavedChangesModalVisible: false,
      modalContinueAction: null,
      imageSource: null,
      imageSourceSmall: null,
      imgWidth: null,
      imgHeight: null,
      isFileTooLarge: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      alert: {
        dismissSecs: 6,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      alertDanger: {
        dismissSecs: 999,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        notification: ''
      },
      ignoreNewSource: false,
      isDraggingFileOver: false,
    }
  },
  computed: {
    isAuthor() {
      return this.$store.state.currentUserRole === "AUTHOR"
    },
    countryLocalization() {
      return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
    },
    preferredLanguage() {
      return this.$store.state.preferredLanguage
    },
    languages() {
      return this.$store.state.languages;
    },
    translatedLanguages() {
      return this.form.localizations.map(localization => localization.language).filter(lang => {
        return lang != this.primaryLanguage
      })
    },
    languagesToBeTranslated() {
      const usedLanguageCodes = this.form.localizations.map(localization => localization.language);
      return this.languages.filter(language => !usedLanguageCodes.includes(language.code));
    },
    originalLanguage() {
      return this.form.originalLanguage ? this.form.originalLanguage : 'cs'
    },
    originalLanguageLocalization() {
      if (!this.form.localizations) return
      return this.form.localizations.find(localization => localization.language == this.originalLanguage)
    },
    isAssigned() {
      if (!this.canDelete) return
      const { travelTips, itineraryDays, itineraryItems} = this.canDelete 
      return travelTips.length > 0 || itineraryDays.length > 0 || itineraryItems.length > 0
    },
    isEditable() {
      if (!this.isAuthor) return true
      if (!this.canDelete) return
      const { travelTips, itineraryDays, itineraryItems} = this.canDelete
      const allowedTravelTipStates = ["IN_PROGRESS", "TO_REWORK"]
      for (const travelTip of travelTips) {
          if (!allowedTravelTipStates.includes(travelTip.state)) {
            return false
          }
      }
      for (const itineraryDay of itineraryDays) {
        if (!allowedTravelTipStates.includes(itineraryDay.travelTip.state)) {
            return false
          }
      }
      for (const itineraryItem of itineraryItems) {
        if (!allowedTravelTipStates.includes(itineraryItem.travelTip.state)) {
            return false
          }
      }
      console.log("yessir editable")
      return true
    },
    filterCountries() {
      if (this.countries) {
        const searchString = sharedUtils.removeAccents(this.countriesSearchString.toUpperCase())
        return this.countries.filter(c => {
          if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString)) return true
          return false
        }).sort((a, b) => {
          return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
        })
      }
      return []
    }
  },
  methods: {
    removePictureToUpload(index) {
      this.picturesToCreate.splice(index, 1)
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFiles(event) {
      const files = event.target.files;
      this.processFiles(files);
    },
    handleDrop(event) {
      event.preventDefault()
      const files = event.dataTransfer.files;
      this.processFiles(files);
    },
    processFiles(files) {
      const fileNamesToUpload = this.picturesToCreate.map(picture => picture.file?.name)
      Array.from(files).forEach((file) => {
        if (fileNamesToUpload.includes(file.name)) {
          return
        }
        if (file.type.startsWith("image/")) {
          this.picturesToCreate.push({
            title: "",
            file: file,
            error: "",
            src: URL.createObjectURL(file)
          })
        }
      });
    },


    createCopyOfForm(form) {
      return {
        ...form,
        country: {...form.country},
        localizations: form.localizations.map(loc => {
          return {...loc}
        })
      }
    },
    discardChangesAndContinue() {
      this.form = this.createCopyOfForm(this.lastSavedForm)
      this.sortLocalizations()
      this.isUnsavedChangesModalVisible = false
      this.modalContinueAction()
    },
    isChangeInForm(callback) {
      const hasFormChanged = JSON.stringify(this.form) != JSON.stringify(this.lastSavedForm)
      if (hasFormChanged) {
        this.modalContinueAction = callback
        this.isUnsavedChangesModalVisible = true
        return true
      }
      
      return false
    },
    createLocalization(languageCode) {
      this.form.localizations.push({
        language: languageCode,
        title: '',
      })
    },
    setOriginalLanguage(languageCode) {
      this.form.originalLanguage = languageCode
      this.primaryLanguage = languageCode
      this.createLocalization(languageCode)
    },

    setPrimaryLanguage(languageCode) {
      if (this.primaryLanguage == languageCode) return
      if (this.isChangeInForm(() => this.setPrimaryLanguage(languageCode))) return
      this.primaryLanguage = languageCode
      if (this.editedLanguage == languageCode) {
        this.editedLanguage = null
      }
      this.sortLocalizations()
    },
    findLanguage(languageCode) {
      return this.languages.find(lang => lang.code == languageCode)
    },
    createTranslation(language) {
      if (this.isChangeInForm(() => this.createTranslation(language))) return
      this.createLocalization(language.code)
      this.showLocalization(language.code, true)
      this.isManagingLocalizations = true
      this.$bvModal.hide('create-language-translation-modal')
    },
    showLocalization(languageCode, isCreated = false) {
      if (this.editedLanguage == languageCode) return
      if (!isCreated) {
        if (this.isChangeInForm(() => this.showLocalization(languageCode, isCreated))) return
      }
      this.editedLanguage = languageCode
      this.sortLocalizations()
    },
    sortLocalizations(){
      sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
      sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
    },
    toggleManageLocalizations() {
      if (this.isChangeInForm(this.toggleManageLocalizations)) return
      this.isManagingLocalizations = !this.isManagingLocalizations
    },
    getDayNumber(itemId) {
      const itineraryItem = this.canDelete.itineraryItems.find(item => item.id == itemId)
      for (const day of itineraryItem.travelTip.itineraryDays) {
        const foundItem = day.itineraryItems.find(item => item.id === itemId);

        if (foundItem) {
          return day.dayNumber;
        }
      }

    },
    onDragOver(event) {
      this.isDraggingFileOver = true
      event.preventDefault();
    },
    onDragLeave(event) {
      this.isDraggingFileOver = false
      event.preventDefault();
    },
    onDrop(event) {
      this.isDraggingFileOver = false
      if (event.dataTransfer.files.length == 0) return 
      const file = event.dataTransfer.files[0]
      event.preventDefault()
      if (!["image/jpeg", "image/png"].includes(file.type)) return this.showAlertDanger(this.$t('alerts.unsupportedFormat', [file.type]))
      this.onInputFile(null, file)
    },
    async onInputFile(event, pictureFile = null){
      const file = pictureFile ? pictureFile : event.target.files[0]
      if (!file || this.ignoreNewSource) return
      let image = document.querySelector('.display-picture');
      image.onload = () => {
        if (this.ignoreNewSource) return this.ignoreNewSource = false
        this.imgWidth = image.naturalWidth
        this.imgHeight = image.naturalHeight
      }
      
      if (file.size > 13000000) {
        this.isFileTooLarge = true
      } else {
        this.isFileTooLarge = false
      }
      this.form.chosenPicture = file
      this.form.fileName = file.name
      image.src = URL.createObjectURL(file);
      this.imageSource = URL.createObjectURL(file);
      this.imageSourceSmall = URL.createObjectURL(file)
      const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(this.form.chosenPicture.name)
      if(this.form.chosenPicture){
        this.form.chosenPicture = new File([this.form.chosenPicture], uniqueNameWithoutAccentsAndSpaces, {type: this.form.chosenPicture.type});
      }
    },
    setImgDimensions(width, height) {
      this.imgWidth = width
      this.imgHeight = height
    },
    onSubmit: async function() {
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0

      if (!this.$route.params.pictureId) {
        if (!this.validateInputsCreate()) return

        this.loading = this.picturesToCreate?.length > 1 ? this.$t("loading.picturesCreate") : this.$t("loading.pictureCreate")
        let createdPictures = []
        let isErrorInTheProcess = false
        for (const [index, picture] of this.picturesToCreate.entries()) {
          const pictureForm = {
            chosenPicture: picture.file,
            countryId: this.form.country.id,
            originalLanguage: this.originalLanguage,
            localizations: [{
              language: this.originalLanguage,
              title: picture.title
            }]
          }
          await ImageService.createPicture(pictureForm)
          .then(({data}) => {
            createdPictures.push(data.createPicture)
          })
          .catch(err => {
            console.log(err)
            isErrorInTheProcess = true
            if (err.toString().includes('exceeded limit')) {
              this.showAlertDanger(this.$t('alerts.maxiumUnassignedPictures'))
            }
            else this.showAlertDanger(this.$t('alerts.pictureCreateError'))
          })
          if (isErrorInTheProcess) {
            //Remove successfully created pictures from picturesToCreate
            this.picturesToCreate.splice(0, index)
            break
          }
        }
        if (!isErrorInTheProcess) {
          this.createNewPictures(createdPictures)
        }
        this.loading = ''
      }
      else {
        if (!this.isManagingLocalizations && !this.validateInputsUpdate()) return
          this.loading = this.$t("loading.pictureUpload")
          if (this.$route.params.pictureId) {
            this.loading = this.$t("loading.pictureUpdate")
            await ImageService.updatePicture(this.form, this.$route.params.pictureId)
              .then(res => this.updateOldPicture(this.$t('alerts.pictureUpdated')))
              .catch(err => {
                console.log(err)
                this.resetToDefault()
                this.showAlertDanger(this.$t('alerts.pictureUpdateForbiddenError'))
              })
          }
      }
    },
    validateInputsCreate() {
      let errorCount = 0

      this.error.country = ""
      if (!this.form.country.id) {
        this.error.country = this.$t('inputErrors.required')
        errorCount += 1
      }
      this.picturesToCreate.forEach(pic => {
        if (!pic.title) {
          errorCount += 1
          pic.error = this.$t("inputErrors.required")
        } else {
          pic.error = ""
        }
      })

      if (errorCount > 0) {
          this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
          return false
      }

      return true
    },
    validateInputsUpdate() {
      this.isManagingLocaulizations = false
      let errorCount = 0
      for (const key in this.error) {
        this.error[key] = ''
      }
      const { title } = this.originalLanguageLocalization
      if (!title) {
        this.error.pictureTitle = this.$t("inputErrors.required")
        errorCount += 1
      }
      if (this.isFileTooLarge) errorCount += 1
      if (!this.form.fileName) {
        this.error.file = this.$t('inputErrors.required')
        errorCount += 1
      }
      if (!this.form.country.id) {
        this.error.country = this.$t('inputErrors.required')
        errorCount += 1
      }
      if (errorCount > 0) {
          this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
          return false
      }

      return true
    },

    onDeleteConfirmed: async function() {
      this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
      this.loadingDelete = this.$t("loading.pictureDelete")
      await ImageService.deletePicture(this.pictureId)
      .then(res => {
        this.$emit("showAlert", this.$t('alerts.pictureDeleted'))
        this.$router.push({name: 'adminPictures'});
      })
      .catch(err => {
        console.log(err)
        this.$bvModal.hide('picture-delete-modal')
        this.showAlertDanger(this.$t('alerts.pictureDeleteForbiddenError'))
      })
      this.loadingDelete = ''
    },

    async createNewPictures(createdPictures) {
      this.initialLoading = false
      this.$emit("showAlert", createdPictures.length > 1 ? this.$t('alerts.picturesCreated') : this.$t('alerts.pictureCreated'))
      if(this.$route.name == "adminManagePicture"){
        this.$router.push({name: 'adminPictures'});
      } else{
        this.$emit('weHaveNewFoto', createdPictures);
      }
    },
    async updateOldPicture(message) {
      this.loading = ''
      this.showAlert(message);
      this.ignoreNewSource = true
    },
    resetToDefault: function() {
      this.loading = ''
      this.loadingDelete = ''
      if(this.picture) {
        this.form = {
          author: this.picture.author.userLogin,
          fileName: this.picture.fileName,
          country: this.picture.country,
          originalLanguage: this.picture.originalLanguage ? this.picture.originalLanguage : 'cs',
          localizations: this.picture.localizations.map(localization => {
            const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
            delete localizationCopy['__typename'];
            return localizationCopy
          })
        }
        this.lastSavedForm = this.createCopyOfForm(this.form)

        if (this.initialLoading) {
          const pictureLocalizationLanguages = this.form.localizations.map(loc => loc.language);
          if (pictureLocalizationLanguages.includes(this.preferredLanguage)) {
            this.primaryLanguage = this.preferredLanguage
          } else if (this.preferredLanguage == 'cs' && pictureLocalizationLanguages.includes('sk')) {
            this.primaryLanguage = 'sk'
          } else {
            this.primaryLanguage = this.form.originalLanguage
          }
        }
        this.sortLocalizations()
        
        // Make sure the orignal language form will be on the left side when editing translations
        this.imageSource = this.imgURL + this.picture.fileName.slice(0, this.picture.fileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + this.picture.fileName.slice(this.picture.fileName.lastIndexOf('.'))
        this.imageSourceSmall = this.imgURL + this.picture.fileName.slice(0, this.picture.fileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + this.picture.fileName.slice(this.picture.fileName.lastIndexOf('.'))
      } else if (this.$store.state.currentUserRole != "ADMIN_SUPERVISOR") {
        this.createLocalization(this.$store.state.preferredLanguage)
      }
    },

    showAlert(notification) {
      this.loading = ''
      this.alert.dismissCountDown = this.alert.dismissSecs
      this.alert.notification = notification;
    },
    showAlertDanger(notification) {
      this.loading = ''
      this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
      this.alertDanger.notification = notification;
    },

    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    },

    countDownChangedDanger(dismissCountDown) {
      this.alertDanger.dismissCountDown = dismissCountDown
    },
  },

  apollo: {
    countries: {
      query: COUNTRIES,
    },
    picture: {
      query: PICTURE_BY_ID,

      variables: function() {
        return {
          pictureId: this.$route.params.pictureId,
        }
      },

      skip: function () {
        return !this.$route.params.pictureId;
      },

    },
    canDelete: {
      query: CAN_DELETE_PICTURE,
      variables: function() {
        return {
          pictureId: this.$route.params.pictureId
        }
      },
      update(data) {
        const {travelTips, itineraryDays, itineraryItems} = data.canDelete
        const newCanDelete = {...data.canDelete}
        if (travelTips?.length) {
          newCanDelete.travelTips = travelTips.map(travelTip => assignTravelTipLocalizationProperties(travelTip))
        }
        if (itineraryDays?.length) {
          newCanDelete.itineraryDays = itineraryDays.map(day => assignItineraryDayLocalizationProperties(day))
        }
        if (itineraryItems?.length) {
          newCanDelete.itineraryItems = itineraryItems.map(item => assignItineraryItemLocalizationProperties(item))
        }

        return newCanDelete
      },
      skip: function () {
        return !this.$route.params.pictureId;
      },
      fetchPolicy: 'cache-and-network'
    }
  },

  watch: {
    '$route.params'() {
      this.pictureId = this.$route.params.pictureId;
    },
    picture () {
      this.resetToDefault()
      if (this.initialLoading) this.initialLoading = false
    },
  },

  created() {
    this.pictureId = this.$route.params.pictureId;
    if (this.$route.name == 'adminManageTravelTip' && this.preselectedCountry) this.form.country = this.preselectedCountry
    this.resetToDefault()
  },
}
</script>

<style scoped lang="scss">
@use '../../common-def.scss';
@import '@/scss/variables';

.input-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .input-with-image {
    display: flex;
    gap: 20px;
    align-items: end;
    height: 140px;
    border-bottom: 1px solid $tr-light-gray;
    padding-bottom: 20px;
    padding-top: 20px;

    &:first-child {
      border-top: 1px solid $tr-light-gray;
    }

    img {
      max-height: 100px;
    }

    .remove-picture {
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      margin-top: 16px;
      margin-right: 10px;
    }
  }
}
.upload-box {
  width: 100%;
  max-width: 1100px;
  height: 200px;
  outline: 2px dashed $tr-light-gray;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.forms-container {
  gap: 30px;
  flex-wrap: wrap;
  padding-bottom: 40px;

  form {
    width: 100%;
    flex: 0 0 calc(50% - 15px);
  }
  .form-content-container {
    gap: 30px;
  }
}

.dashed-border {
  outline: dashed 2px $tr-black;
}

.admin-page-loading {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: calc(50% + 140px);
  font-size: 20px;
}

.buttons-localization {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  gap: 20px;
  width: 100%
}

.language-option {
  height: 60px;
  width: 200px;
  padding: 20px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $tr-green;
  }
}

.input-alert {
  color: $tr-pink;
  font-size: 12px;
}

.form {
  &#password {
    padding-right: 55px;
  }
}
.dropdown-input {
  height: 60px;
  width: 100%;
  padding-inline: 20px;
  border: none;
  border-bottom: solid 2px $tr-gray;
  background-color: $tr-white;
}
.file-input-container {
  max-width: 700px;
  width: 100%;
  position: relative;

  label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $tr-white-alt;
    padding: 10px 0 0 20px;
    cursor: pointer;
    color: $tr-gray;
    font-size: 11px;
    line-height: 16px;

    &.white-bg {
      background-color: $tr-white
    }

    &[data-required]::after {
      content: "*";
      margin-left: 3px;
    } 
  }
  .file-input-name {
    width: calc(100% - 180px);
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap;
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 2;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
  .upload-button {
    position: absolute;
    right: 0;
    z-index: 2;
    width: 140px;
    min-width: 140px;
    pointer-events: none;
  }

  .hint {
    color: $tr-gray;
    font-size: 11px;
  }

  .file-input-wrapper {
    height: 60px;
  }

  .file-input-wrapper.size-error {
    outline: $tr-pink solid 2px !important;

    & + .hint {
      color: $tr-pink
    }
  }
  .file-input-wrapper.error {
    outline: $tr-pink solid 2px !important;
  }
}

.display-picture {
  width: 330px;
  height: 250px;
  cursor: pointer;
  object-fit: cover;
}

  .picture-assigned-to-container {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding-block: 20px;
    max-width: 1200px;

    .picture-is-assigned-to-title {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 600;
    }

  }
  
  .assigned-to-container {
    display: flex;
    gap: 40px;

    & > div {
      flex: 1;
      max-width: 400px;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: $tr-green;
    }

    b {
      color: #8a8a8a !important;
    }

    .assigned-to-category {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p, b {
        color: $tr-gray;
      }
    }

    .assigned-to-title {
      margin-bottom: 0;
      font-size: 18px;
      color: $tr-black !important;
    }
  }

  .fixed-buttons {
    margin-top: 40px;
  }

  .manage-picture-page {
    padding-bottom: 80px;

    .fixed-buttons {
      display: flex;
      justify-content: center;
      max-width: calc(100% - 300px);
      width: 100%;
      bottom: 0;
      left: 300px;
      background-color: $tr-white;
      border-top: 1px solid $tr-light-gray;
      position: fixed;
      z-index: 1030;
      transition: 0.3s ease-in-out;
      transition-property: left, max-width;
      margin-top: 0 !important;
  
      &.full-width {
        max-width: 100%;
        left: 0
      }
  
      & > div {
        max-width: 1090px;
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 40px;
        padding: 10px 40px;
  
        & > div {
          max-width: 330px;
          width: 100%;
        }
      }
  
      &.manage-localization {
        & > div {
          justify-content: unset;
  
          & > div {
            margin-left: calc(50% + 15px);
          }
        }
      }
    }
  }

</style>