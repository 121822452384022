import gql from "graphql-tag";

export const CREATE_PICTURE = gql`
    mutation createPicture ($file: Upload!, $country: ID!, $originalLanguage: String!, $localizations: [PictureLocalizationInput]) {
        createPicture (file: $file, country: $country, originalLanguage: $originalLanguage, localizations: $localizations) {
            id
            fileName
            country {
                id
                code
                nameCzech
            }
            author {
                id
                userName
            }
            localizations {
                language
                title
            }
        }
    }`

export const UPDATE_PICTURE = gql`
    mutation updatePicture ($id: ID!, $file: Upload, $country: ID!, $originalLanguage: String!, $localizations: [PictureLocalizationInput]) {
        updatePicture(id: $id, file: $file, country: $country, originalLanguage: $originalLanguage, localizations: $localizations) {
            id
            fileName
            country {
                id
                code
                nameCzech
            }
            author {
                id
                userName
            }
            localizations {
                title
            }
        }
    }`

export const DELETE_PICTURE = gql`
    mutation deletePicture ($pictureId: Int!) {
        deletePicture(pictureId: $pictureId)
    }`