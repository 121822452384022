import gql from 'graphql-tag'

export const PICTURES = gql`
    query AllPictures($filter: PicturesFilter, $pageSize: PageSize) {
        pictures: picturesAdmin (filter: $filter, pageSize: $pageSize) {
            id
            fileName
            author {
                id
                userName
            }
            country {
                id
                code
                nameCzech
                nameEnglish
            }
            originalLanguage
            localizations {
                language
                title
            }
        }
    }`

export const PICTURES_ADMIN_BY_AUTHOR_ID = gql`
    query picturesAdminByUserId($userId: Int!) {
        picturesToGetCountries: picturesAdminByUserId (userId: $userId) {
            id
            country {
                id
                code
                nameCzech
                nameEnglish
            }
        }
    }`;

export const PICTURES_FOR_COUNTRIES = gql`
    query PicturesForCountries($filter: PicturesFilter) {
        picturesToGetCountries: picturesAdmin (filter: $filter) {
            id
            country {
                id
                code
                nameCzech
                nameEnglish
            }
            author {
                id
                userName
            }
        }
    }`
export const PICTURE_BY_ID = gql`
    query PitcureById($pictureId: Int!) {
        picture: pictureAdmin (pictureId: $pictureId) {
            id
            fileName
            originalLanguage
            localizations {
                language
                title
            }
            country {
                id
                nameCzech
                nameEnglish
            }
            author {
                userName
            }
            originalLanguage
        }
    }`

export const CAN_DELETE_PICTURE = gql`
    query canDelete($pictureId: Int!) {
        canDelete (pictureId: $pictureId) {
            travelTips {
                state
                id
                localizations {
                    title
                }
            }
            itineraryDays {
                id
                localizations {
                    title
                }
                travelTip {
                    id
                    state
                    localizations {
                        title
                    }
                }
            }
            itineraryItems {
                id
                localizations {
                    title
                }
                travelTip {
                    id
                    state
                    localizations {
                        title
                    }
                    itineraryDays {
                        dayNumber
                        itineraryItems {
                            id
                        }
                    }
                }
            }
        }
    }`
