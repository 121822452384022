export function assignLocalizationProperties(item, language = null) {
    if (item.localizations?.length) {
        if (language) {
           const localizationIndex = item.localizations.findIndex(loc => loc.language === language)
           if (localizationIndex != -1) {
            return {...item.localizations[localizationIndex], ...item}
           }
        }
        return {...item, ...item.localizations[0]}
    }
    return {...item}
}

export function assignCampaignLocalizationProperties(campaign, language = null) {
    const newCampaign = assignLocalizationProperties(campaign, language)
    if (newCampaign.travelTips?.length) {
        newCampaign.travelTips = newCampaign.travelTips.map(travelTip => {
            return assignTravelTipLocalizationProperties(travelTip, language)
        })
    }
    return newCampaign

}
export function assignTravelTipLocalizationProperties(travelTip, language = null) {
    const newTravelTip = assignLocalizationProperties(travelTip, language)
    if (newTravelTip.itineraryDays?.length) {
        newTravelTip.itineraryDays = newTravelTip.itineraryDays.map(day => {
            return assignItineraryDayLocalizationProperties(day, language) 
        });
    }
    if (newTravelTip.campaign && newTravelTip.campaign.localizations?.length) {
        newTravelTip.campaign = assignLocalizationProperties(newTravelTip.campaign, language)
    }
    if (newTravelTip.titlePicture) {
        newTravelTip.titlePicture = assignLocalizationProperties(newTravelTip.titlePicture, language)
    }
    return newTravelTip
}
export function assignItineraryDayLocalizationProperties(itineraryDay, language = null) {
    const newItineraryDay = assignLocalizationProperties(itineraryDay, language)
    if (newItineraryDay.itineraryItems?.length) {
        newItineraryDay.itineraryItems = newItineraryDay.itineraryItems.map(item => {
            return assignItineraryItemLocalizationProperties(item, language)
        });
    }
    if (newItineraryDay.picture) {
        newItineraryDay.picture = assignLocalizationProperties(newItineraryDay.picture, language)
    }

    if (newItineraryDay.travelTip) {
        newItineraryDay.travelTip = assignLocalizationProperties(newItineraryDay.travelTip, language)
    }
    return newItineraryDay
}

export function assignItineraryItemLocalizationProperties(itineraryDay, language = null) {
    const newItineraryItem = assignLocalizationProperties(itineraryDay, language)
    if (newItineraryItem.itineraryItemPictures?.length) {
        newItineraryItem.itineraryItemPictures = newItineraryItem.itineraryItemPictures.map(picture => {
            return assignLocalizationProperties(picture, language)
        });
    }
    if (newItineraryItem.travelTip) {
        newItineraryItem.travelTip = assignLocalizationProperties(newItineraryItem.travelTip, language)
    }
    return newItineraryItem
}